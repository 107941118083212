import {useState, createContext} from 'react'

export const UploadPortalsTabsContext = createContext(false)

export const UploadPortalsTabsProvider = ({children}) => {
  const [selectedTab, setSelectedTab] = useState('generated-portals')
  const toggleSelectedTabToGeneratedPortals = () => {
    setSelectedTab('generated-portals')
  }
  const toggleSelectedTabToMySubmissions = () => {
    setSelectedTab('my-submissions')
  }
  return (
    <UploadPortalsTabsContext.Provider
      value={{
        selectedTab,
        toggleSelectedTabToGeneratedPortals,
        toggleSelectedTabToMySubmissions,
      }}
    >
      {children}
    </UploadPortalsTabsContext.Provider>
  )
}
