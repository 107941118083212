import {useState, createContext} from 'react'

export const TabsContext = createContext(false)

export const TabsProvider = ({children}) => {
  const [activeTab, setActiveTab] = useState('asset-library')
  const toggleActiveTabToPosts = () => {
    setActiveTab('posts')
  }
  const toggleActiveTabToAssets = () => {
    setActiveTab('asset-library')
  }
  const toggleActiveTabToOffers = () => {
    setActiveTab('offers')
  }
  const toggleActiveTabToUploadPortals = () => {
    setActiveTab('upload-portals')
  }
  const toggleActiveTabToLicenses = () => {
    setActiveTab('licenses')
  }
  const toggleActiveTabToSettings = () => {
    setActiveTab('settings')
  }
  const toggleActiveTabToPaymentHistory = () => {
    setActiveTab('payment-history')
  }
  const toggleActiveTabToSmarterLinks = () => {
    setActiveTab('smarter-links')
  }

  const [userId, setUserId] = useState()
  return (
    <TabsContext.Provider
      value={{
        activeTab,
        toggleActiveTabToAssets,
        toggleActiveTabToOffers,
        toggleActiveTabToUploadPortals,
        toggleActiveTabToLicenses,
        toggleActiveTabToSettings,
        toggleActiveTabToPosts,
        toggleActiveTabToPaymentHistory,
        toggleActiveTabToSmarterLinks,
        setUserId,
        userId,
      }}
    >
      {children}
    </TabsContext.Provider>
  )
}
