import {useState, createContext} from 'react'

export const AssetLibraryTabsContext = createContext(false)

export const AssetLibraryTabsProvider = ({children}) => {
  const [selectedTab, setSelectedTab] = useState('my-assets')
  const toggleSelectedTabToMyAssets = () => {
    setSelectedTab('my-assets')
  }
  const toggleSelectedTabToPurchasedAssets = () => {
    setSelectedTab('purchased-assets')
  }
  return (
    <AssetLibraryTabsContext.Provider
      value={{
        selectedTab,
        toggleSelectedTabToMyAssets,
        toggleSelectedTabToPurchasedAssets,
      }}
    >
      {children}
    </AssetLibraryTabsContext.Provider>
  )
}
