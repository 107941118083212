/* const Login = lazy(async () => await import("pages/Login/Login")); */
import {lazy} from 'react'

const Login = lazy(async () => await import('pages/Login'))
const LandingPage = lazy(async () => await import('pages/LandingPage'))
const CompleteProfile = lazy(async () => await import('pages/CompleteProfile'))
const ProfileCongratulation = lazy(
  async () => await import('pages/ProfileCongratulation')
)
const TermsAndPrivacy = lazy(async () => await import('pages/TermsAndPrivacy'))
const Register = lazy(async () => await import('pages/Register'))
const ForgotPassword = lazy(async () => await import('pages/ForgotPassword'))
const ChangePassword = lazy(async () => await import('pages/ChangePassword'))
const StripeSuccess = lazy(async () => await import('pages/StripeSuccess'))
const NotFound = lazy(async () => await import('pages/NotFound'))
const OfferOverview = lazy(async () => await import('pages/OfferOverview'))
const OfferPreview = lazy(async () => await import('pages/OfferPreview'))
const SubmitAssets = lazy(async () => await import('pages/SubmitAssets'))
const SubmitAssetPortal = lazy(
  async () => await import('pages/SubmitAssetPortal')
)
const Profile = lazy(async () => await import('pages/Profile'))
const ProfilePublic = lazy(async () => await import('pages/ProfilePublic'))
const Shop = lazy(async () => await import('pages/Shop'))
const Post = lazy(async () => await import('pages/Post'))
const PostPreview = lazy(async () => await import('pages/PostPreview'))
const GeneratedOffer = lazy(async () => await import('pages/GeneratedOffer'))
const BulkOffers = lazy(async () => await import('pages/BulkOffers'))
const ReceivedOffer = lazy(async () => await import('pages/ReceivedOffer'))
const GeneratedPortal = lazy(async () => await import('pages/GeneratedPortal'))
const GeneratedSubmission = lazy(
  async () => await import('pages/GeneratedSubmission')
)
const ReceivedPortal = lazy(async () => await import('pages/ReceivedPortal'))
const ReceivedSubmission = lazy(
  async () => await import('pages/ReceivedSubmission')
)
const EmbedPortal = lazy(async () => await import('pages/EmbedPortal'))
const UploadAsset = lazy(async () => await import('pages/UploadAsset'))
const CompletedTransaction = lazy(
  async () => await import('pages/CompletedTransaction')
)
const PurchaseCompleted = lazy(
  async () => await import('pages/PurchaseCompleted')
)
const AssetSubmitted = lazy(async () => await import('pages/AssetSubmitted'))
const PayoutInfo = lazy(async () => await import('pages/PayoutInfo'))
const Cart = lazy(async () => await import('pages/Cart'))
const Checkout = lazy(async () => await import('pages/Checkout'))
const OrderSummary = lazy(async () => await import('pages/OrderSummary'))
const AddCreditCard = lazy(async () => await import('pages/AddCreditCard'))
const HelpCenter = lazy(async () => await import('pages/HelpCenter'))
const ExploreAllPortals = lazy(
  async () => await import('pages/ExploreAllPortals')
)
const ExploreAllCreators = lazy(
  async () => await import('pages/ExploreAllCreators')
)
const ExploreAllBuyers = lazy(
  async () => await import('pages/ExploreAllBuyers')
)
const CreateSmartLink = lazy(async () => await import('pages/CreateSmartLink'))
const SmarterLink = lazy(async () => await import('pages/SmarterLink'))
const SmarterLogs = lazy(async () => await import('pages/SmarterLogs'))
const ShareLink = lazy(async () => await import('pages/ShareLink'))
const ShareLogs = lazy(async () => await import('pages/ShareLogs'))
const EmbedFile = lazy(async () => await import('pages/EmbedFile'))
const EmbedOwnFile = lazy(async () => await import('pages/EmbedOwnFile'))
const SmarterFilePreview = lazy(
  async () => await import('pages/SmarterFilePreview')
)
const SuccessfulSubmission = lazy(
  async () => await import('pages/SuccessfulSubmission')
)
const SuccessfulTransaction = lazy(
  async () => await import('pages/SuccessfulTransaction')
)
const Marketplace = lazy(async () => await import('pages/Marketplace'))
const MarketplaceSearch = lazy(
  async () => await import('pages/MarketplaceSearch')
)
const PrivacyPolicy = lazy(async () => await import('pages/PrivacyPolicy'))
const TermsOfService = lazy(async () => await import('pages/TermsOfService'))
const JoinWaitlistForm = lazy(async () => await import('pages/WaitlistForm'))

export const routes = [
  {
    path: '/',
    name: 'LandingPage',
    exact: true,
    component: LandingPage,
    protected: false,
    full: true,
  },
  {
    path: '/complete-profile',
    name: 'Complete Profile',
    exact: true,
    component: CompleteProfile,
    protected: true,
  },
  {
    path: '/finished-profile',
    name: 'Profile Congratulation',
    exact: true,
    component: ProfileCongratulation,
    protected: true,
  },
  {
    path: '/login',
    name: 'Login',
    exact: true,
    component: Login,
    protected: false,
  },
  {
    path: '/register/:id',
    name: 'Register',
    exact: true,
    component: Register,
    protected: false,
  },
  {
    path: '/register',
    name: 'Register',
    exact: true,
    component: Register,
    protected: false,
  },
  {
    path: '/forgot-password',
    name: 'Register',
    exact: true,
    component: ForgotPassword,
    protected: false,
  },
  {
    path: '/change-password/:id',
    name: 'Change Password',
    exact: false,
    component: ChangePassword,
    protected: false,
  },
  {
    path: '/stripe-success',
    name: 'Stripe Success',
    exact: true,
    component: StripeSuccess,
    protected: true,
  },
  {
    path: '/terms-and-privacy',
    name: 'Register',
    exact: true,
    component: TermsAndPrivacy,
    protected: false,
  },
  {
    path: '/offer-overview/:id',
    name: 'Offer Overview',
    exact: true,
    component: OfferOverview,
    protected: true,
  },
  {
    path: '/offer-review/:id',
    name: 'Offer Review',
    exact: true,
    component: OfferPreview,
    protected: false,
  },
  {
    path: '/submit-assets/:id',
    name: 'Submit Assets',
    exact: true,
    component: SubmitAssets,
    protected: true,
  },
  {
    path: '*',
    name: 'NotFound',
    exact: false,
    component: NotFound,
    protected: false,
  },
  {
    path: '/profile/:id/shop',
    name: 'Shop',
    exact: false,
    component: Shop,
    protected: false,
  },
  {
    path: '/profile/:id',
    name: 'Profile Public',
    exact: false,
    component: ProfilePublic,
    protected: false,
  },
  {
    path: '/profile',
    name: 'Profile',
    exact: true,
    component: Profile,
    protected: true,
  },
  {
    path: '/post/:id',
    name: 'Post',
    exact: false,
    component: Post,
    protected: true,
  },
  {
    path: '/post-preview/:id',
    name: 'Post',
    exact: false,
    component: PostPreview,
    protected: false,
  },
  {
    path: '/generated-offer/:id',
    name: 'Generated Offer',
    exact: false,
    component: GeneratedOffer,
    protected: true,
  },
  {
    path: '/bulk-offers/:id',
    name: 'Bulk Offers',
    exact: false,
    component: BulkOffers,
    protected: true,
  },
  {
    path: '/upload-asset',
    name: 'Upload Asset',
    exact: true,
    component: UploadAsset,
    protected: true,
  },
  {
    path: '/upload-asset/:id',
    name: 'Upload Asset',
    exact: false,
    component: UploadAsset,
    protected: true,
  },
  {
    path: '/received-offer/:id',
    name: 'Received Offer',
    exact: false,
    component: ReceivedOffer,
    protected: true,
  },
  {
    path: '/generated-portal/:id',
    name: 'Upload Portal',
    exact: false,
    component: GeneratedPortal,
    protected: true,
  },
  {
    path: '/upload-portal/:id',
    name: 'Upload Portal',
    exact: false,
    component: ReceivedPortal,
    protected: false,
  },
  {
    path: '/upload-portal/:id/submit',
    name: 'Upload Portal - Submit Asset',
    exact: true,
    component: SubmitAssetPortal,
    protected: true,
  },
  {
    path: '/upload-portal/:id/submission/:id',
    name: 'Submission',
    exact: false,
    component: GeneratedSubmission,
    protected: true,
  },
  {
    path: '/generated-portal/:id/submission/:id',
    name: 'Submission',
    exact: false,
    component: ReceivedSubmission,
    protected: true,
  },
  {
    path: '/generated-portal/:id/submission/:id',
    name: 'Submission',
    exact: false,
    component: ReceivedOffer,
    protected: true,
  },
  {
    path: '/completed-transaction',
    name: 'Completed Transaction',
    exact: false,
    component: CompletedTransaction,
    protected: true,
  },
  {
    path: '/purchase-completed',
    name: 'Purchase Completed',
    exact: false,
    component: PurchaseCompleted,
    protected: true,
  },
  {
    path: '/asset-submitted',
    name: 'Asset Submitted',
    exact: false,
    component: AssetSubmitted,
    protected: true,
  },
  {
    path: '/payout-info',
    name: 'Payout Info',
    exact: false,
    component: PayoutInfo,
    protected: true,
  },
  {
    path: '/cart',
    name: 'Cart',
    exact: true,
    component: Cart,
    protected: true,
  },
  {
    path: '/checkout',
    name: 'Checkout',
    exact: true,
    component: Checkout,
    protected: true,
  },
  {
    path: '/order-summary',
    name: 'Order Summary',
    exact: true,
    component: OrderSummary,
    protected: true,
  },
  {
    path: '/add-credit-card',
    name: 'Add Credit Card',
    exact: true,
    component: AddCreditCard,
    protected: true,
  },
  {
    path: '/explore-portals',
    name: 'Explore All Portals',
    exact: true,
    component: ExploreAllPortals,
    protected: false,
  },
  {
    path: '/explore-creators',
    name: 'Explore All Creators',
    exact: true,
    component: ExploreAllCreators,
    protected: false,
  },
  {
    path: '/explore-buyers',
    name: 'Explore All Buyers',
    exact: true,
    component: ExploreAllBuyers,
    protected: false,
  },
  {
    path: '/create-smarter-link',
    name: 'Create Smart Link',
    exact: true,
    component: CreateSmartLink,
    protected: true,
  },
  {
    path: '/smarter-link/:id',
    name: 'SmarterLink',
    exact: false,
    component: SmarterLink,
    protected: false,
  },
  {
    path: '/smarter-logs/:id',
    name: 'SmarterLogs',
    exact: false,
    component: SmarterLogs,
    protected: true,
  },
  {
    path: '/smarter-link/:id/share/:id',
    name: 'SmarterLink',
    exact: false,
    component: ShareLink,
    protected: false,
  },
  {
    path: '/smarter-logs/:id/share/:shareId',
    name: 'SmarterLogs',
    exact: false,
    component: ShareLogs,
    protected: true,
  },
  {
    path: '/embed-file/:id/share/:shareId',
    name: 'Embed File',
    exact: false,
    component: EmbedFile,
    protected: false,
  },
  {
    path: '/embed-file/:id',
    name: 'Embed Own File',
    exact: false,
    component: EmbedOwnFile,
    protected: false,
  },
  {
    path: '/smarter-file/:id',
    name: 'Smarter File',
    exact: false,
    component: SmarterFilePreview,
    protected: true,
  },
  {
    path: '/embed-portal/:id',
    name: 'Embed Portal',
    exact: false,
    component: EmbedPortal,
    protected: false,
  },
  {
    path: '/successful-submission',
    name: 'Successful Submission',
    exact: true,
    component: SuccessfulSubmission,
    protected: false,
  },
  {
    path: '/successful-transaction',
    name: 'Successful Transaction',
    exact: true,
    component: SuccessfulTransaction,
    protected: false,
  },
  {
    path: '/marketplace',
    name: 'Marketplace',
    exact: false,
    component: Marketplace,
    protected: false,
  },
  {
    path: '/search',
    name: 'Marketplace',
    exact: false,
    component: MarketplaceSearch,
    protected: false,
  },
  {
    path: '/help',
    component: HelpCenter,
    full: true,
  },
  {
    path: '/help/:page',
    component: HelpCenter,
    full: true,
  },
  {
    path: '/privacypolicy',
    exact: false,
    component: () =>
      window.location.replace('https://home.smarterlicense.com/privacypolicy'),
    full: true,
  },

  {
    path: '/privacy-policy',
    exact: false,
    component: () =>
      window.location.replace('https://home.smarterlicense.com/privacypolicy'),
    full: true,
  },
  {
    path: '/termsofservice',
    exact: false,
    component: () =>
      window.location.replace('https://home.smarterlicense.com/termsofservice'),
    full: true,
  },
  {
    path: '/terms-of-service',
    exact: false,
    component: () =>
      window.location.replace('https://home.smarterlicense.com/termsofservice'),
    full: true,
  },
  {
    path: '/joinwaitlist',
    component: JoinWaitlistForm,
    full: true,
  },
  {
    path: '/deck',
    exact: false,
    component: () =>
      window.location.replace('https://docsend.com/view/s/eb6w8czipnt4gkdg'),
    full: true,
  },
  {
    path: '/bugs',
    exact: false,
    component: () =>
      window.location.replace('https://formfaca.de/sm/zAK9kXUgr'),
    full: true,
  },
  {
    path: '/bug',
    exact: false,
    component: () =>
      window.location.replace('https://formfaca.de/sm/zAK9kXUgr'),
    full: true,
  },
]

export default routes
