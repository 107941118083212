import {useState, createContext} from 'react'

export const DismissAddBillingInfoContext = createContext(false)

export const DismissAddBillingInfoProvider = ({children}) => {
  const [dismissAddBillingInfo, setDismissAddBillingInfo] = useState(false)

  return (
    <DismissAddBillingInfoContext.Provider
      value={{
        dismissAddBillingInfo,
        setDismissAddBillingInfo,
      }}
    >
      {children}
    </DismissAddBillingInfoContext.Provider>
  )
}
