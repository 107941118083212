import React, {useContext} from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {AuthContext} from 'contexts/AuthContext'

const PrivateRoute = ({children, isAuth, full, path, protect}) => {
  let location = useLocation()
  const {state} = useContext(AuthContext)

  if (!state.isAuth && location.pathname.includes('post')) {
    return (
      <Navigate
        to={`/post-preview/${location?.pathname?.split('/')[2]}`}
        state={{from: location, post: location.pathname}}
        replace
      />
    )
  }

  if (!state.isAuth) {
    return (
      <Navigate
        to="/login"
        state={{from: location, post: location.pathname}}
        replace
      />
    )
  }

  return children
}

export default PrivateRoute
