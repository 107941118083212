import React from 'react'
import ReactDOM from 'react-dom/client'
import {QueryClient, QueryClientProvider} from 'react-query'
import './index.scss'
import App from 'pages/App/App'
import {AuthProvider} from 'contexts/AuthContext'
import {TabsProvider} from 'contexts/TabsContext'
import {OffersTabsProvider} from 'contexts/OffersTabsContext'
import {AssetLibraryTabsProvider} from 'contexts/AssetLibraryTabsContext'
import {SettingsTabsProvider} from 'contexts/SettingsTabsContext'
import {DismissAddBillingInfoProvider} from 'contexts/DismissAddBillingInfoContext'
import {ToastContainer} from 'react-toastify'
import {UploadPortalsTabsProvider} from 'contexts/UploadPortalsTabsContext'
import {UserTypeViewProvider} from 'contexts/UserTypeViewContext'
import {GoogleOAuthProvider} from '@react-oauth/google'

const queryClient = new QueryClient()

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
  <QueryClientProvider client={queryClient}>
    <GoogleOAuthProvider clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID}>
      <TabsProvider>
        <UploadPortalsTabsProvider>
          <OffersTabsProvider>
            <AssetLibraryTabsProvider>
              <SettingsTabsProvider>
                <DismissAddBillingInfoProvider>
                  <AuthProvider>
                    <UserTypeViewProvider>
                      <App />
                    </UserTypeViewProvider>
                    <ToastContainer
                      style={{minWidth: '350px'}}
                      position="bottom-center"
                      autoClose={5000}
                      hideProgressBar={false}
                      closeOnClick
                      pauseOnHover
                      draggable
                      progress={undefined}
                      theme="colored"
                    />
                  </AuthProvider>
                </DismissAddBillingInfoProvider>
              </SettingsTabsProvider>
            </AssetLibraryTabsProvider>
          </OffersTabsProvider>
        </UploadPortalsTabsProvider>
      </TabsProvider>
    </GoogleOAuthProvider>
  </QueryClientProvider>
)
