import instagram from 'assets/img/icons/instagram.svg'
import tiktok from 'assets/img/icons/tiktok.svg'
import facebook from 'assets/img/icons/facebook.svg'
import twitter from 'assets/img/icons/twitter.svg'
import twitch from 'assets/img/icons/twitch.svg'
import youtube from 'assets/img/icons/youtube.svg'
import discord from 'assets/img/icons/discord.svg'
import linkedin from 'assets/img/icons/linkedin.svg'
import substack from 'assets/img/icons/substack.svg'
import medium from 'assets/img/icons/medium.svg'
import applePodcast from 'assets/img/icons/apple-podcast.svg'
import spotify from 'assets/img/icons/spotify.svg'
import threads from 'assets/img/icons/threads.svg'
import bluesky from 'assets/img/icons/bluesky.svg'
import {
  ALLOWED_IMAGE_EXT,
  ALLOWED_IMAGE_SIZE,
  ALLOWED_SOCIALS_LIST,
  ALLOWED_VIDEO_EXT,
  ALLOWED_VIDEO_SIZE,
  SUPPORTED_SOCIAL_MEDIA_DOMAINS,
} from './constants'
import {toast} from 'react-toastify'
import jwtEncode from 'jwt-encode'
import {getItemFromStorage} from './storage'

export const verifyAllowedURLs = (link) => {
  if (link.includes('http') || link.includes('https')) {
    try {
      const url = new URL(link)
      let domain = url.hostname.replace('www.', '').split('.')[0]
      if (ALLOWED_SOCIALS_LIST.includes(domain)) {
        return true
      }
      return false
    } catch (_) {
      return false
    }
  } else {
    try {
      const url = link.split('www.').pop().split('.')[0]
      if (ALLOWED_SOCIALS_LIST.includes(url)) {
        return true
      }
      return false
    } catch (_) {
      return false
    }
  }
}

export const getURLDomain = (link) => {
  if (link.includes('http') || link.includes('https')) {
    if (link.includes('http://www') || link.includes('https://www')) {
      const url = new URL(link)
      let domain = url.hostname.replace('www.', '').split('.')[0]
      return domain
    } else {
      const domain = link?.split('//')[1]?.split('.')[0]
      return domain
    }
  } else {
    const domain = link.split('www.').pop().split('.')[0]
    return domain
  }
}

export const returnSocialIcon = (name) => {
  switch (name) {
    case 'instagram':
      return instagram
    case 'tiktok':
      return tiktok
    case 'facebook':
      return facebook
    case 'twitter':
      return twitter
    case 'twitch':
      return twitch
    case 'youtube':
      return youtube
    case 'discord':
      return discord
    case 'linkedin':
      return linkedin
    case 'substack':
      return substack
    case 'medium':
      return medium
    case 'podcasts':
      return applePodcast
    case 'apple':
      return applePodcast
    case 'spotify':
      return spotify
    case 'threads':
      return threads
    case 'bsky':
      return bluesky
    default:
      return
  }
}

export const bytesToMegaBytes = (bytes) => {
  return (bytes / (1024 * 1024)).toFixed(1)
}

export const verifyImageSize = (file) => {
  const fileSize = bytesToMegaBytes(file.size)
  if (fileSize > ALLOWED_IMAGE_SIZE) return false
  return true
}

export const verifyImageType = (file) => {
  const imageType = file.type.split('/')[1]
  if (ALLOWED_IMAGE_EXT[imageType] === undefined) return false
  return true
}

export const verifyVideoSize = (file) => {
  const fileSize = bytesToMegaBytes(file.size)
  if (fileSize > ALLOWED_VIDEO_SIZE) return false
  return true
}

export const verifyVideoType = (file) => {
  const videoType = file.type.split('/')[1]
  if (!ALLOWED_VIDEO_EXT[videoType]) return false
  return true
}

export const getLicenses = (licenses) => {
  if (!licenses) return {}
  let base = licenses?.find((value) => value.type === 'base')?.price
  let standard = licenses?.find((value) => value.type === 'standard')?.price
  let premium = licenses?.find((value) => value.type === 'premium')?.price
  let ai = licenses?.find((value) => value.type === 'ai')?.price
  return {
    baseLicencePrice: base,
    standardLicencePrice: standard,
    premiumLicencePrice: premium,
    aiLicencePrice: ai,
  }
}

export const downloadFile = ({value}, name, type) => {
  if (value?.specs?.type === 'image') type = 'png'
  if (value?.specs?.type === 'video') type = 'mp4'
  if (value?.specs?.type === 'audio') type = 'mp3'
  if (value?.specs?.type === 'pdf') type = 'pdf'
  if (value?.specs?.type === 'document') type = 'doc'
  if (value?.specs?.type === 'spreadsheet') type = 'xls'
  if (value?.specs?.type === 'archive') type = 'zip'
  try {
    let element = document.createElement('a')
    element.href = value.url
    element.download = `${name}${value?.specs?.ext}`
    element.click()
    element.remove()
  } catch (error) {
    toast.error('Issue downloading this asset')
  }
}

const isURLValid = (string) => {
  try {
    new URL(string)
    return true
  } catch (_) {
    return false
  }
}

export const generateURL = (url) => {
  const baseUrl = 'https://www.example.com'
  let newUrl = ''
  if (isURLValid(url)) return url
  else newUrl = new URL('//' + url, baseUrl).href
  return newUrl
}

export const getFileSpecifications = async (file) => {
  let list = null
  const fileType = file.type.split('/')[0]
  const fileExt = file.type.split('/')[1]
  if (fileType === 'image') {
    let width = 0
    let height = 0
    if (fileExt !== 'heic' && fileExt !== 'heif' && fileExt !== 'tiff') {
      const values = await getImageSpecs(URL.createObjectURL(file))
      width = values.width
      height = values.height
    }
    list = {
      width: width,
      height: height,
      size: bytesToMegaBytes(file.size),
      type: fileType,
      ext: fileExt,
    }
  }
  if (fileType === 'video') {
    const {width, height, duration} = await getVideoSpecs(
      URL.createObjectURL(file)
    )
    list = {
      width: width,
      height: height,
      size: bytesToMegaBytes(file.size),
      duration: duration,
      type: fileType,
      ext: fileExt,
    }
  }
  if (fileType === 'audio') {
    const {duration} = await getAudioSpecs(URL.createObjectURL(file))
    list = {
      size: bytesToMegaBytes(file.size),
      duration: duration,
      type: fileType,
      ext: fileExt,
    }
  }
  {
    /*if (fileType === 'text') {
    list = {
      size: bytesToMegaBytes(file.size),
      type: fileType,
      ext: fileExt,
    }
  }*/
  }
  if (fileExt === 'vnd.ms-excel' || fileExt === 'csv') {
    list = {
      size: bytesToMegaBytes(file.size),
      type: 'spreadsheet',
      ext: fileExt,
    }
  }
  if (fileExt === 'zip') {
    list = {
      size: bytesToMegaBytes(file.size),
      type: 'archive',
      ext: fileExt,
    }
  }
  if (fileExt === 'json') {
    list = {
      size: bytesToMegaBytes(file.size),
      type: 'json',
      ext: fileExt,
    }
  }
  if (fileExt === 'pdf') {
    list = {
      size: bytesToMegaBytes(file.size),
      type: fileExt,
      ext: fileExt,
    }
  }
  if (
    fileExt === 'vnd.openxmlformats-officedocument.wordprocessingml.document'
  ) {
    list = {
      size: bytesToMegaBytes(file.size),
      type: 'document',
      ext: fileExt,
    }
  }

  if (fileExt === 'msword') {
    list = {
      size: bytesToMegaBytes(file.size),
      type: 'document',
      ext: fileExt,
    }
  }

  return list
}

export const getImageSpecs = async (url) => {
  return new Promise((resolve) => {
    const img = document.createElement('img')

    img.onload = function () {
      const height = this.naturalHeight
      const width = this.naturalWidth
      const size = this.size

      resolve({height, width, size})
    }

    img.src = url
  })
}

export const getVideoSpecs = (url) => {
  return new Promise((resolve) => {
    const video = document.createElement('video')

    video.addEventListener(
      'loadedmetadata',
      function () {
        const height = this.videoHeight
        const width = this.videoWidth
        const duration = this.duration
        const size = this.size

        resolve({height, width, duration, size})
      },
      false
    )

    video.src = url
  })
}

export const getAudioSpecs = (url) => {
  return new Promise((resolve) => {
    const audio = document.createElement('audio')

    audio.addEventListener(
      'loadedmetadata',
      function () {
        const duration = this.duration
        const size = this.size

        resolve({duration, size})
      },
      false
    )

    audio.src = url
  })
}

export const uuidv4 = () => {
  return ([1e7] + -1e3 + -4e3 + -8e3 + -1e11).replace(/[018]/g, (c) =>
    (
      c ^
      (crypto.getRandomValues(new Uint8Array(1))[0] & (15 >> (c / 4)))
    ).toString(16)
  )
}

export const generateZendeskJWT = () => {
  const profile = getItemFromStorage({name: 'profile'})
  if (!profile) return null
  const d = new Date()
  const seconds = Math.round(d.getTime() / 1000)
  const secret = process.env.REACT_APP_ZENDESK_ID
  const data = {
    iat: seconds,
    jti: uuidv4(),
    name: `${profile?.firstName} ${profile?.lastName}`,
    email: profile?.email,
    organization: 'Postmarket',
    tags: 'postmarket_user',
    locale_id: '1',
  }
  const jwt = jwtEncode(data, secret)
  return jwt
}

export const handleHelpCenter = () => {
  const jwtToken = generateZendeskJWT()
  if (jwtToken) {
    const url = `${process.env.REACT_APP_ZENDESK_URL}/access/jwt?jwt=${jwtToken}`
    window.location.replace(url)
  } else window.location.replace(`${process.env.REACT_APP_ZENDESK_URL}/hc`)
}

// Get the list of embeddable social media urls
// convert them to an array of only the profile urls
export const getSupportedSocialMediaUrls = (urlsArr) => {
  if (urlsArr) {
    const supportedSocialMediaUrls = urlsArr
      // filtering out all the invalid urls
      .filter((urlObj) => {
        const {source} = urlObj
        return SUPPORTED_SOCIAL_MEDIA_DOMAINS.includes(source)
      })
      // returning a new array of only the profile urls, not the object
      .map((validUrlObj) => {
        return validUrlObj.url
      })

    return supportedSocialMediaUrls
  } else {
    return []
  }
}

// Check strings to see if it is a valid url format
export const isValidUrl = (urlStr) => {
  try {
    new URL(urlStr)
    return true
  } catch {
    return false
  }
}

// Check to see if the host in the url string is the same as the expected string
// used to see if the url inserted is roughly validated for a social media site
export const isSupportedSocialUrl = (urlStr, expectedSource) => {
  if (isValidUrl(urlStr)) {
    const urlObj = new URL(urlStr)
    const {host} = urlObj
    return host.includes(expectedSource.toLowerCase())
  } else {
    return false
  }
}
