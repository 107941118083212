import {useState} from 'react'
import {Navigate, useLocation} from 'react-router-dom'
import {handleHelpCenter} from 'utils/helpers'

const PublicRoute = ({children, isAuth}) => {
  const [navigateOnce, setNavigateOnce] = useState(false)
  let location = useLocation()
  const buyingOffer = location.pathname.split('/')[2]

  if (isAuth && window.location.search === '?type=sso') handleHelpCenter()
  if (
    (isAuth && location?.pathname.indexOf('/profile/') === 0) ||
    location?.pathname.indexOf('/post/') === 0 ||
    location?.pathname.indexOf('/post-preview/') === 0
  ) {
    if (!navigateOnce) {
      setNavigateOnce(true)
      return (
        <Navigate
          state={{from: location, buyingOfferId: buyingOffer}}
          replace
        />
      )
    }
  } else if (isAuth && !location.pathname.indexOf('upload-portal')) {
    return (
      <Navigate
        to={buyingOffer ? `/offer-overview/${buyingOffer}` : '/profile'}
        state={{from: location, buyingOfferId: buyingOffer}}
        replace
      />
    )
  }

  return children
}

export default PublicRoute
