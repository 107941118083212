import {useState, createContext} from 'react'

export const SettingsTabsContext = createContext(false)

export const SettingsTabsProvider = ({children}) => {
  const [selectedTab, setSelectedTab] = useState('my-account')
  const toggleSelectedTabToMyAccount = () => {
    setSelectedTab('my-account')
  }
  const toggleSelectedTabToPaymentMethods = () => {
    setSelectedTab('payment-methods')
  }
  const toggleSelectedTabToConnectedAccounts = () => {
    setSelectedTab('connected-accounts')
  }
  return (
    <SettingsTabsContext.Provider
      value={{
        selectedTab,
        toggleSelectedTabToMyAccount,
        toggleSelectedTabToPaymentMethods,
        toggleSelectedTabToConnectedAccounts,
      }}
    >
      {children}
    </SettingsTabsContext.Provider>
  )
}
