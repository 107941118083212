import {useState, createContext} from 'react'

export const OffersTabsContext = createContext(false)

export const OffersTabsProvider = ({children}) => {
  const [selectedTab, setSelectedTab] = useState('generated-offers')
  const toggleSelectedTabToGeneratedOffers = () => {
    setSelectedTab('generated-offers')
  }
  const toggleSelectedTabToReceivedOffers = () => {
    setSelectedTab('received-offers')
  }
  return (
    <OffersTabsContext.Provider
      value={{
        selectedTab,
        toggleSelectedTabToGeneratedOffers,
        toggleSelectedTabToReceivedOffers,
      }}
    >
      {children}
    </OffersTabsContext.Provider>
  )
}
