import axios from 'axios'
import {removeItemFromStorage, setDataInStorage} from 'utils/storage'

const proxy = process.env.REACT_APP_STAGING_API_URL

export const getToken = () => JSON.parse(localStorage.getItem('user') || '{}')

export const getMyProfile = async () => {
  const {token} = getToken()
  const response = await axios(proxy + `/users/profile/myProfile`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    setDataInStorage({
      name: 'profile',
      data: {
        ...response.data,
        displayName:
          response.data.organization ||
          `${response.data.firstName} ${response.data.lastName}`,
      },
    })
    return response.data
  }
  return null
}

export const getUserByUsername = async (id) => {
  const response = await axios(proxy + `/users/${id}/profile`, {
    method: 'GET',
  })
  if (response && response.data) {
    return {
      ...response.data,
      displayName:
        response.data.organization ||
        `${response.data.firstName} ${response.data.lastName}`,
    }
  }
  return null
}

export const getOffersCreated = async (pageNumber, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/buyingOffer/offers/created?from=${from + 1}&size=${size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {buyingOffersGenerated, totalCount} = response.data
    return {offers: buyingOffersGenerated, totalCount}
  }
  return null
}

export const getAllOffersCreated = async (totalCount) => {
  const {token} = getToken()
  const response = await axios(
    proxy + `/buyingOffer/offers/created?size=${totalCount}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {buyingOffersGenerated, totalCount} = response.data
    return {offers: buyingOffersGenerated, totalCount}
  }
  return null
}

export const getOffersReceived = async (pageNumber, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/buyingOffer/offers/received?from=${from + 1}&size=${size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {buyingOffersReceived, totalCount} = response.data
    return {offers: buyingOffersReceived, totalCount}
  }
  return null
}

export const getAllOffersReceived = async (totalCount) => {
  const {token} = getToken()
  const response = await axios(
    proxy + `/buyingOffer/offers/received?size=${totalCount}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {buyingOffersReceived, totalCount} = response.data
    return {offers: buyingOffersReceived, totalCount}
  }
  return null
}

export const createOfferLink = async (data) => {
  const {token} = getToken()
  try {
    const response = await axios(proxy + '/buyingOffers', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
    if (response && response.data) {
      return response.data
    }
    return null
  } catch (err) {
    let displayError = 'Error: Unable to complete request'
    if (err.response) {
      displayError = err?.response?.data?.message ?? displayError
    }
    throw new Error(displayError)
  }
}

export const deleteOffer = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/buyingOffer/delete/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const registerUser = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + '/register', {
    method: 'POST',
    headers: {token},
    data: data,
  })
  if (response && response.data) {
    setDataInStorage({name: 'user', data: response.data})
    return response.data
  }
  return null
}

export const loginUser = async (data, tokens) => {
  const {token} = getToken()
  const response = await axios(proxy + '/login', {
    method: 'POST',
    headers: {token: tokens || token},
    data: data,
  })
  if (response && response.data) {
    setDataInStorage({name: 'user', data: response.data})
    return response.data
  }
  return null
}

export const googleSignIn = async (data) => {
  const response = await axios(proxy + '/googleSignIn', {
    method: 'POST',
    data,
  })
  if (response && response.data) {
    setDataInStorage({name: 'user', data: response.data})
    return response.data
  }
  return null
}

export const logoutUser = async ({sessionId, token}) => {
  const response = await axios(proxy + '/logout', {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      sessionId,
    },
  })
  if (response && response.data) {
    removeItemFromStorage({name: 'user'})
    return response.data
  }
  return null
}

export const forgotPassword = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + '/forgotPassword', {
    method: 'POST',
    headers: {token},
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const completeProfile = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + '/users/completeProfile', {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getBuyingOffer = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/buyingOffer/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getBuyingOfferPublic = async (id) => {
  const response = await axios(proxy + `/buyingOffer/${id}/public`, {
    method: 'GET',
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const acceptBuyingOffer = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/buyingOffer/${id}/accept`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })

  if (response && response.data) {
    return response.data
  }
  return response
}

export const editMyProfile = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + '/users/editProfile', {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    setDataInStorage({name: 'profile', data: response.data})
  }
  return null
}

export const submitAssets = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + `/medias/upload/asset`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const createPost = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + `/posts`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const acceptBuyingOfferByRequester = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/buyingOffer/${id}/requester/accept`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const declineBuyingOfferByRequester = async ({id, declineReason}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/buyingOffer/${id}/requester/decline`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      declineReason,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const declineBuyingOfferByCreator = async ({id, declineReason}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/buyingOffer/${id}/decline`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      declineReason,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const askForResubmission = async ({id, resubmissionReason}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/buyingOffer/${id}/resubmit`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      resubmissionReason,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getAvatar = async (id, size) => {
  const {token} = getToken()
  let url = proxy + `/avatar/${id}`
  if (size) url += `?width=${size}&height=${size}`

  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })
  if (response && response.data) {
    return URL.createObjectURL(response.data)
  }
  return null
}

export const getPublicAvatar = async (id) => {
  const response = await axios(proxy + `/avatar/${id}`, {
    method: 'GET',
    responseType: 'blob',
  })
  if (response && response.data) {
    return URL.createObjectURL(response.data)
  }
  return null
}

export const uploadAvatar = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + `/medias/upload/avatar`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getOffer = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/buyingOffer/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const updatePassword = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + `/users/updatePassword`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const resetPassword = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + `/resetPassword`, {
    method: 'POST',
    headers: {token},
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getCreatedPosts = async (pageNumber, size = 10) => {
  const {token} = getToken()

  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/posts/assets?from=${from + 1}&size=${size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {myAssets, totalCount} = response.data
    return {assets: myAssets, totalCount}
  }
  return null
}

export const getPublicCreatedPosts = async (id, pageNumber = 1, size = 10) => {
  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/posts/${id}/assets?from=${from + 1}&size=${size}`,
    {
      method: 'GET',
    }
  )
  if (response && response.data) {
    const {myAssets, totalCount} = response.data
    return {assets: myAssets, totalCount}
  }
  return null
}

export const getOneCreatedPost = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/posts/assets/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getOneCreatedPostPublic = async (id) => {
  const response = await axios(proxy + `/posts/assets/${id}/public`, {
    method: 'GET',
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getPostsAssetPublic = async ({id, type, size = 300}) => {
  let url = proxy + `/assets/${id}`
  if (type === 'image') url += `?width=${size}&height=${size}`
  const response = await axios(url, {
    method: 'GET',
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    else file = new Blob([response.data])
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const getPostsAsset = async ({id, type, size}) => {
  const {token} = getToken()
  let url = proxy + `/assets/${id}`
  if (type === 'image') url += size ? `?width=${size}&height=${size}` : ''
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    else file = new Blob([response.data])
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const getPurchasedAssetByPostId = async ({postId}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/assets/${postId}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    else file = new Blob([response.data])

    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
      },
    }
  }
  return null
}

export const getPublicPostsAsset = async ({id, type, size}) => {
  const {token} = getToken()
  const authHeader = token ? `Bearer ${token}` : undefined
  let url = proxy + `/assets/${id}`
  if (type === 'image') url += `?width=${size}&height=${size}`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: authHeader,
    },
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    else file = new Blob([response.data])
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const getPostsPurchased = async (pageNumber, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/posts/purchased?from=${from + 1}&size=10`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {purchasedAssets, totalCount} = response.data
    return {assets: purchasedAssets, totalCount}
  }
  return null
}

export const createAssetPost = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + `/posts/create`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getNotificationsCount = async () => {
  const {token} = getToken()
  const response = await axios(proxy + `/notifications/count`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getNotifications = async (pageNumber = 1, size = 5) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/notifications?from=${from + 1}&size=${size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    return response.data
  }
  return null
}

export const markAsRead = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/notifications/${id}/read`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const markAllAsRead = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/notifications/read`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getAssetByBuyingOfferId = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/buyingOffers/${id}/asset`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const getAssetBySubmissionId = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/submissions/${id}/asset`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const addCard = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + '/stripe/creditCards', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const setStripeOnboarding = async (country) => {
  const {token} = getToken()
  const response = await axios(proxy + '/stripe/startOnboardingFlow', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: country,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const updateStripeOnboarding = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + '/stripe/updateOnboardingFlow', {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const retrieveStripeAccount = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + '/stripe/retrieveAccount', {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const purchaseAsset = async ({postData, postId}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/posts/${postId}/purchase`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: postData,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const generateLicense = async ({purchaseId}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/post/${purchaseId}/licence`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })
  if (response && response.data) {
    return URL.createObjectURL(response.data)
  }
  return null
}

export const getCreditCards = async () => {
  const {token} = getToken()
  const response = await axios(proxy + `/stripe/creditCards`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const payment = async ({cardId, postId}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/asset/${postId}/payment`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {cardId},
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const deleteCreatedAsset = async (postId) => {
  const {token} = getToken()
  const response = await axios(proxy + `/posts/${postId}/delete`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getCheckoutPrices = async (purchaseId) => {
  const {token} = getToken()
  const response = await axios(proxy + `/asset/${purchaseId}/prices`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getLicenses = async (pageNumber, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(proxy + `/licences?from=${from + 1}&size=10`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {licences, totalCount} = response.data
    return {licenses: licences, totalCount}
  }
  return null
}

export const getAllLicenses = async (totalCount) => {
  const {token} = getToken()
  const response = await axios(proxy + `/licences?size=${totalCount}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {licences, totalCount} = response.data
    return {licenses: licences, totalCount}
  }
  return null
}

export const getTransactions = async (pageNumber, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(proxy + `/payments?from=${from + 1}&size=10`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {paymentHistory, totalCount} = response.data
    return {transactions: paymentHistory, totalCount}
  }
  return null
}

export const deletePostKeyword = async ({id, keyword}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/posts/${id}/keywords`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      keyword,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const deletePostSocialUrl = async ({id, url}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/posts/${id}/socialMediaUrls`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      url,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const deletePostSocialAttribution = async ({id, url}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/posts/${id}/creditsAndAttributions`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      url,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const disconnectCreditCard = async (cardId) => {
  const {token} = getToken()
  const response = await axios(proxy + `/stripe/creditCards/${cardId}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const redirectToStripeDashboard = async () => {
  const {token} = getToken()
  const response = await axios(proxy + '/stripe/dashboard', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const disconnectStripeAccount = async () => {
  const {token} = getToken()
  const response = await axios(proxy + '/stripe/account/', {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const joinWaitlist = async (data) => {
  const response = await axios(proxy + '/waitlist', {
    method: 'POST',
    data,
  })
    .then((res) => res)
    .catch((err) => {
      let displayError = 'Error: Unable to complete request'
      if (err.response) {
        displayError = err?.response?.data?.message ?? displayError
      }
      throw new Error(displayError)
    })

  return response
}

export const createUploadPortal = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + '/portals', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getPortalsCreated = async (id, pageNumber, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/users/${id}/portals?from=${from + 1}&size=${size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {portals, totalCount} = response.data
    return {portals, totalCount}
  }
  return null
}

export const getMySubmissions = async (id, pageNumber, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/users/${id}/submissions?from=${from + 1}&size=${size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {submissions, totalCount} = response.data
    return {submissions, totalCount}
  }
  return null
}

export const getMyPortalSubmissions = async (id, pageNumber, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy +
      `/users/${id}/submissions?from=${from + 1}&size=${size}&type=portal`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {submissions, totalCount} = response.data
    return {submissions, totalCount}
  }
  return null
}

export const deletePortal = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/portals/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const deleteSubmission = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/submissions/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const previewLicense = async (data) => {
  const response = await axios(proxy + '/licenses/generate', {
    method: 'POST',
    data,
    responseType: 'arraybuffer',
  })
    .then((res) => res.data)
    .catch((err) => {
      let displayError = 'Error: Unable to complete request'
      if (err.response) {
        displayError = err?.response?.data?.message ?? displayError
      }
      throw new Error(displayError)
    })

  return response
}

export const getPortalSubmissions = async (id, pageNumber, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/portals/${id}/submissions?from=${from + 1}&size=${size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {submissions, totalCount} = response.data
    return {submissions: submissions, totalCount}
  }
  return null
}

export const getAllPortalSubmissions = async (id, totalCount) => {
  const {token} = getToken()
  const response = await axios(
    proxy + `/portals/${id}/submissions?size=${totalCount}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {submissions, totalCount} = response.data
    return {submissions: submissions, totalCount}
  }
  return null
}

export const acceptSubmissionByRequester = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/submissions/${id}/accept`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const declineSubmissionByRequester = async ({id, declineReason}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/submissions/${id}/decline`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      declineReason,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const askSubmissionForResubmission = async ({
  id,
  resubmissionReason,
}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/submissions/${id}/resubmit`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      resubmissionReason,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const editUploadPortal = async ({id, data}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/portals/${id}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data,
  })

  if (response && response.data) {
    return response.data
  }
  return response
}

export const getCreatedPortal = async (id) => {
  const response = await axios(proxy + `/portals/${id}`, {
    method: 'GET',
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getReceivedPortal = async (id) => {
  const response = await axios(proxy + `/portals/${id}`, {
    method: 'GET',
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getTrendingPortals = async () => {
  const response = await axios(proxy + `/portals`, {
    method: 'GET',
  })
  if (response && response.data) {
    const {portals, totalCount} = response.data
    return {portals: portals, totalCount}
  }
  return null
}

export const getTopTrendingPortals = async ({from, size}) => {
  const response = await axios(proxy + `/portals?from=${from}&size=${size}`, {
    method: 'GET',
  })
  if (response && response.data) {
    const {portals, totalCount} = response.data
    return {portals: portals, totalCount}
  }
  return null
}

export const getPortalsById = async (id) => {
  const response = await axios(proxy + `/users/${id}/portals`, {
    method: 'GET',
  })
  if (response && response.data) {
    const {portals, totalCount} = response.data
    return {portals, totalCount}
  }
  return null
}

export const getUsers = async () => {
  const response = await axios(proxy + `/users`, {
    method: 'GET',
  })
  if (response && response.data) {
    const {users, totalCount} = response.data
    return {users, totalCount}
  }
  return null
}

export const getTopTrendingCreators = async ({from, size}) => {
  const response = await axios(
    proxy + `/users?type=INDIVIDUAL&from=${from}&size=${size}`,
    {
      method: 'GET',
    }
  )
  if (response && response.data) {
    const {users, totalCount} = response.data
    return {users, totalCount}
  }
  return null
}

export const getTopTrendingBuyers = async ({from, size}) => {
  const response = await axios(
    proxy + `/users?type=BRAND&from=${from}&size=${size}`,
    {
      method: 'GET',
    }
  )
  if (response && response.data) {
    const {users, totalCount} = response.data
    return {users, totalCount}
  }
  return null
}

export const createSubmission = async ({submissionData, id}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/portals/${id}/submissions`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: submissionData,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getSubmission = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/submissions/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getAssetByOfferId = async ({id, type, size}) => {
  const {token} = getToken()
  let url = proxy + `/buyingOffers/${id}/asset`
  if (type === 'image') url += size ? `?width=${size}&height=${size}` : ''
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    else file = new Blob([response.data])
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const getAssetBySubmission = async ({id, type, size}) => {
  const {token} = getToken()
  let url = proxy + `/submissions/${id}/asset`
  if (type === 'image') url += size ? `?width=${size}&height=${size}` : ''
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    else file = new Blob([response.data])
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const uploadLogo = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + `/medias/upload/logo`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const uploadFavicon = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + `/medias/upload/logo`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getLogo = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/logo/${id}?type=web`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const getFavicon = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/logo/${id}?type=favicon`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const deleteLogo = async () => {
  const {token} = getToken()
  const response = await axios(proxy + `/logo`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      type: 'web',
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const deleteFavicon = async (type) => {
  const {token} = getToken()
  const response = await axios(proxy + `/logo`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {
      type: 'favicon',
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const createSmarterLink = async (data) => {
  const {token} = getToken()
  try {
    const response = await axios(proxy + '/smartLinks', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
    if (response && response.data) {
      return response.data
    }
    return null
  } catch (err) {
    let displayError = 'Error: Unable to complete request'
    if (err.response) {
      displayError = err?.response?.data?.message ?? displayError
    }
    throw new Error(displayError)
  }
}

export const getSmarterLink = async ({id, data}) => {
  const response = await axios(proxy + `/smartLinks/${id}`, {
    method: 'POST',
    data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getSmarterLinks = async (pageNumber, type, order, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  let url = proxy + `/smartLinks?from=${from + 1}&size=${size}`
  if (type && !order) url += `&type=${type}`
  if (order && !type) url += `&orderByDate=${order}`
  if (order && type) url += `&type=${type}&orderByDate=${order}`

  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getSmarterLogs = async (
  id,
  pageNumber,
  term,
  order,
  fromDate,
  toDate,
  size = 10
) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  let url = proxy + `/smartLinks/${id}/logs?from=${from + 1}&size=${size}`
  if (term) url += `&term=${term}`
  if (order) url += `&orderByDate=${order}`
  if (fromDate) url += `&fromDate=${fromDate}`
  if (toDate) url += `&toDate=${toDate}`

  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getAllSmarterLinks = async (totalCount) => {
  const {token} = getToken()
  const response = await axios(proxy + `/smartLinks?size=${totalCount}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    const {smartLinks, totalCount} = response.data
    return {smartLinks: smartLinks, totalCount}
  }
  return null
}

export const getAllSmarterLogs = async (id, totalCount) => {
  const {token} = getToken()
  const response = await axios(
    proxy + `/smartLinks/${id}/logs?size=${totalCount}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {smartLinkLogs, totalCount} = response.data
    return {smartLinkLogs: smartLinkLogs, totalCount}
  }
  return null
}

export const submitSmarterFile = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + `/medias/upload/smartLinks`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getSmarterFile = async (id, shareId) => {
  let url = proxy + `/smartLink/${id}?shareId=${shareId}`
  const response = await axios(url, {
    method: 'GET',

    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    else file = new Blob([response.data])
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
      file: file,
    }
  }
  return null
}

export const getOwnSmarterFile = async (id) => {
  const {token} = getToken()
  let url = proxy + `/smartLink/${id}`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    else file = new Blob([response.data])
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
      file: file,
    }
  }
  return null
}

export const createSmarterLicense = async (data) => {
  const {token} = getToken()
  try {
    const response = await axios(proxy + '/customLicenses', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
    if (response && response.data) {
      return response.data
    }
    return null
  } catch (err) {
    let displayError = 'Error: Unable to complete request'
    if (err.response) {
      displayError = err?.response?.data?.message ?? displayError
    }
    throw new Error(displayError)
  }
}

export const submitSmarterLicense = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + `/medias/upload/license`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
      'Content-Type': 'multipart/form-data',
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getCustomLicense = async (id) => {
  const response = await axios(proxy + `/customLicense/${id}?type=main`, {
    method: 'GET',
    responseType: 'blob',
  })
  if (response && response.data) {
    return URL.createObjectURL(response.data)
  }
  return null
}

export const getSmarterLinkInfo = async ({id}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/smartLinks/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const banSmarterLink = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + '/smartLinks/logs/ban', {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const createShareLink = async (id, data) => {
  const {token} = getToken()
  try {
    const response = await axios(proxy + `/smartLinks/${id}/share`, {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      data: data,
    })
    if (response && response.data) {
      return response.data
    }
    return null
  } catch (err) {
    let displayError = 'Error: Unable to complete request'
    if (err.response) {
      displayError = err?.response?.data?.message ?? displayError
    }
    throw new Error(displayError)
  }
}

export const getShareLink = async ({id, shareId, data}) => {
  const response = await axios(proxy + `/smartLinks/${id}/share/${shareId}`, {
    method: 'POST',
    data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getShareLinks = async (id, pageNumber, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  let url = proxy + `/smartLinks/${id}/shares?from=${from + 1}&size=${size}`

  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const revokeSmarterLink = async ({id, revoke}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/smartLinks/${id}?revoke=${revoke}`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const revokeShareLink = async ({id, revoke}) => {
  const {token} = getToken()
  const response = await axios(
    proxy + `/smartLinks/share/${id}?revoke=${revoke}`,
    {
      method: 'PUT',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getShareLogs = async (
  id,
  shareId,
  pageNumber,
  term,
  order,
  fromDate,
  toDate,
  size = 10
) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  let url =
    proxy +
    `/smartLinks/${id}/logs?shareId=${shareId}&from=${from + 1}&size=${size}`
  if (term) url += `&term=${term}`
  if (order) url += `&orderByDate=${order}`
  if (fromDate) url += `&fromDate=${fromDate}`
  if (toDate) url += `&toDate=${toDate}`

  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const deleteShareLink = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/smartLinks/share/${id}/delete`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const deleteSmarterLink = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/smartLinks/${id}`, {
    method: 'DELETE',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getDirectFile = async (id, shareId) => {
  const response = await axios(
    proxy + `/smartLinks/${id}/file?shareId=${shareId}`,
    {
      method: 'GET',
    }
  )
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getOwnDirectFile = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/smartLinks/${id}/file`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getShareLinkById = async ({id}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/smartLinks/share/${id}`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const editShareLinkPasscode = async (id) => {
  const {token} = getToken()
  const response = await axios(proxy + `/smartLinks/share/${id}/password`, {
    method: 'PUT',
    headers: {
      Authorization: `Bearer ${token}`,
    },
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getAttachment = async (id) => {
  const response = await axios(proxy + `/customLicense/${id}?type=additional`, {
    method: 'GET',
    responseType: 'blob',
  })
  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    else file = new Blob([response.data])
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const previewCustomLicense = async (id) => {
  const response = await axios(proxy + `/customLicense/${id}?type=main`, {
    method: 'GET',
    responseType: 'arraybuffer',
  })
    .then((res) => res.data)
    .catch((err) => {
      let displayError = 'Error: Unable to complete request'
      if (err.response) {
        displayError = err?.response?.data?.message ?? displayError
      }
      throw new Error(displayError)
    })

  return response
}

export const getSmarterLinkInfoUnlogged = async ({id}) => {
  const response = await axios(proxy + `/smartLinks/${id}`, {
    method: 'GET',
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const createTemporaryAccount = async (data) => {
  const response = await axios(proxy + '/users/temp', {
    method: 'POST',
    data,
  })
  if (response && response.data) {
    setDataInStorage({name: 'user', data: response.data})
    return response.data
  }
  return null
}

export const activateTemporaryAccount = async (data) => {
  const response = await axios(proxy + '/users/temp/activate', {
    method: 'POST',
    data,
  })
  if (response && response.data) {
    setDataInStorage({name: 'user', data: response.data})
    return response.data
  }
  return null
}

export const previewCustomLicenseFormats = async (id) => {
  const response = await axios(proxy + `/customLicense/${id}?type=main`, {
    method: 'GET',
    responseType: 'blob',
  })
  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    else file = new Blob([response.data])
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const downloadCustomLicense = async (id) => {
  const response = await axios(proxy + `/customLicense/${id}?type=main`, {
    method: 'GET',
    responseType: 'blob',
  })
  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    else file = new Blob([response.data])
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const getAssetByMediaId = async (id) => {
  const response = await axios(proxy + `/medias/${id}/asset`, {
    method: 'GET',
    headers: {},
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const getCollectionBySubmission = async ({id, type, size}) => {
  const {token} = getToken()
  let url = proxy + `/submissions/${id}/collection`
  if (type === 'image') url += size ? `?width=${size}&height=${size}` : ''
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    else file = new Blob([response.data])
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const getReceipt = async ({purchaseId}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/post/${purchaseId}/invoice`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })
  if (response && response.data) {
    return URL.createObjectURL(response.data)
  }
  return null
}

export const createBulkOffers = async (data) => {
  const {token} = getToken()
  try {
    const response = await axios(proxy + '/buyingOffers/bulk', {
      method: 'POST',
      headers: {
        Authorization: `Bearer ${token}`,
        'Content-Type': 'multipart/form-data',
      },
      data: data,
    })
    if (response && response.data) {
      return response.data
    }
    return null
  } catch (err) {
    let displayError = 'Error: Unable to complete request'
    if (err.response) {
      displayError = err?.response?.data?.message ?? displayError
    }
    throw new Error(displayError)
  }
}

export const getBulkOffersCSV = async ({id}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/buyingOffers/bulk/${id}/file`, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })
  if (response && response.data) {
    return URL.createObjectURL(response.data)
  }
  return null
}

export const getBulkOffers = async (id, pageNumber, size = 10) => {
  const {token} = getToken()
  const from = pageNumber * size - size
  const response = await axios(
    proxy + `/buyingOffer/offers/created/${id}?from=${from + 1}&size=${size}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {buyingOffersGenerated, totalCount} = response.data
    return {offers: buyingOffersGenerated, totalCount}
  }
  return null
}

export const getAllBulkOffers = async (id, totalCount) => {
  const {token} = getToken()
  const response = await axios(
    proxy + `/buyingOffer/offers/created/${id}?size=${totalCount}`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
    }
  )
  if (response && response.data) {
    const {buyingOffersGenerated, totalCount} = response.data
    return {offers: buyingOffersGenerated, totalCount}
  }
  return null
}

export const bulkAcceptOffers = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + `/buyingOffers/accept`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const generateJSONLicense = async ({purchaseId}) => {
  const {token} = getToken()
  const response = await axios(
    proxy + `/post/${purchaseId}/licence?format=json`,
    {
      method: 'GET',
      headers: {
        Authorization: `Bearer ${token}`,
      },
      responseType: 'blob',
    }
  )
  if (response && response.data) {
    return URL.createObjectURL(response.data)
  }
  return null
}

export const getAssetByOfferIdSmall = async ({id, type, size}) => {
  const {token} = getToken()
  let url = proxy + `/buyingOffers/${id}/asset?width=${size}&height=${size}`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    else file = new Blob([response.data])
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const paymentOffers = async ({cardId, postIds}) => {
  const {token} = getToken()
  const response = await axios(proxy + `/assets/payment`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: {postIds, cardId},
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const previewEmptyLicense = async ({data, downloadType}) => {
  const response = await axios(
    proxy + `/licenses/generate?format=${downloadType}`,
    {
      method: 'POST',
      data,
      responseType: 'blob',
    }
  )
  if (response && response.data) {
    return URL.createObjectURL(response.data)
  }
}

export const downloadEmptyLicense = async ({data, downloadType}) => {
  const response = await axios(
    proxy + `/licenses/generate?format=${downloadType}`,
    {
      method: 'POST',
      data,
      responseType: 'blob',
    }
  )

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const getAssetBySubmissionIdSmall = async ({id, type, size}) => {
  const {token} = getToken()
  let url = proxy + `/submissions/${id}/asset?width=${size}&height=${size}`
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    else file = new Blob([response.data])
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const bulkAcceptSubmissions = async (data) => {
  const {token} = getToken()
  const response = await axios(proxy + `/submissions/accept`, {
    method: 'POST',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    data: data,
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getAssetsCollection = async ({id, type, size}) => {
  const {token} = getToken()
  let url = proxy + `/assets/${id}/collection`
  if (type === 'image') url += size ? `?width=${size}&height=${size}` : ''
  const response = await axios(url, {
    method: 'GET',
    headers: {
      Authorization: `Bearer ${token}`,
    },
    responseType: 'blob',
  })

  if (response && response.data) {
    const fileType = response.headers.get('x-mediaType')
    let file = response.data
    if (fileType === 'pdf')
      file = new Blob([response.data], {type: 'application/pdf'})
    else file = new Blob([response.data])
    const fileURL = URL.createObjectURL(file)
    return {
      url: fileURL,
      specs: {
        type: fileType,
        width: response.headers.get('x-width'),
        height: response.headers.get('x-height'),
        size: response.headers.get('x-size'),
        duration: response.headers.get('x-duration'),
        ext: response.headers.get('x-ext'),
      },
    }
  }
  return null
}

export const getFeaturedPosts = async () => {
  const response = await axios(proxy + `/marketplace/posts/featured`, {
    method: 'GET',
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getRisingStars = async () => {
  const response = await axios(proxy + `/marketplace/posts/rising`, {
    method: 'GET',
  })
  if (response && response.data) {
    return response.data
  }
  return null
}

export const getSearchSuggestions = async (search) => {
  const response = await axios(
    proxy + `/marketplace/posts/suggestions?search=${search}`,
    {
      method: 'GET',
    }
  )
  if (response && response.data) {
    const {
      suggestions,
      postsSuggestions,
      keywordsSuggestions,
      usersSuggestions,
    } = response.data
    return {
      suggestions,
      postsSuggestions,
      keywordsSuggestions,
      usersSuggestions,
    }
  }
  return null
}

export const getPosts = async ({
  pageNumber,
  term,
  priceOrder,
  dateOrder,
  size = 20,
}) => {
  const from = pageNumber * size - size
  let url = proxy + `/marketplace/posts?from=${from + 1}&size=${size}`
  if (term) url += `&search=${term}`
  if (priceOrder && !dateOrder) url += `&price=${priceOrder}`
  if (dateOrder && !priceOrder) url += `&date=${dateOrder}`
  const response = await axios(url, {
    method: 'GET',
  })
  if (response && response.data) {
    const {searchAssets, totalCount} = response.data
    return {searchAssets, totalCount}
  }
  return null
}

export const getAllUsers = async ({pageNumber, term, size = 20}) => {
  const from = pageNumber * size - size
  let url = proxy + `/marketplace/users?from=${from + 1}&size=${size}`
  if (term) url += `&search=${term}`
  const response = await axios(url, {
    method: 'GET',
  })
  if (response && response.data) {
    const {search, totalCount} = response.data
    return {searchUsers: search, totalCount}
  }
  return null
}
