import React from 'react'
import Lottie from 'lottie-react'
import loading from 'assets/animation/loading.json'

const Loading = () => {
  return (
    <div className="w-full h-screen flex justify-center items-center flex-col">
      <Lottie animationData={loading} loop={true} className="w-60" />
      <div className="font-canela text-lg text-slate-400">Loading...</div>
    </div>
  )
}

export default Loading
