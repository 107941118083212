export const ALLOWED_SOCIALS_LIST = [
  'facebook',
  'twitch',
  'youtube',
  'tiktok',
  'instagram',
  'twitter',
  'linkedin',
  'substack',
  'podcasts',
  'spotify',
  'apple',
  'medium',
  'discord',
  'open.spotify',
  'podcasts.apple',
  'threads',
  'bsky',
]

export const ALLOWED_IMAGE_EXT = {
  jpeg: 'jpeg',
  jpg: 'jpg',
  png: 'png',
  tiff: 'tiff',
  gif: 'gif',
  heic: 'heic',
  heif: 'heif',
}
export const ALLOWED_IMAGE_SIZE = 15

export const ALLOWED_VIDEO_EXT = {mp4: 'mp4', quicktime: 'quicktime'}
export const ALLOWED_VIDEO_SIZE = 400

export const SUPPORTED_SOCIAL_MEDIA_DOMAINS = [
  'instagram',
  'tiktok',
  'twitter',
  'twitch',
  'substack',
  // 'linkedin',
  'facebook',
  'medium',
  'spotify',
  'apple',
  // 'discord', // <-- requires users to enable widget on their discord server settings
  'youtube',
  'threads',
  'bsky',
]
